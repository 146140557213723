import React from "react";

import {
  isMobile
} from "react-device-detect";

import JobFilters from "./JobFilters";
import JobDescription from "./JobDescription";
import JobItem from "./JobItem";
import JobShimmerItem from "./JobShimmerItem";

import StickyBox from "react-sticky-box";

import LazyLoadingList from "../common/LazyLoadingList";
import JobShimmerDescription from "./JobShimmerDescription";
import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Indeed from "../../../utils/Indeed";
import Paginator from "../common/Paginator";
import AuthManager from "../../../utils/AuthManager";

export default class JobResults extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      filters: props.filters,
      items: [],
      noResults: false,
      viewedJobs: [],
      loading: true,
      selectedJob: null,
      searchTerm: props.searchTerm,
      location: props.location,
      pageCount: props.pageCount ? props.pageCount : 1,
      partner: props.partner,
      companySlug: props.companySlug,
      page: props.page,
      setUp: false
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _applyContracts(jobContracts){
    let contracts = this._formatFilterIds(jobContracts) || 'all'

    this.setState({
      contractsFilter: `contract=${contracts}`,
    })

    this._scrollToTop()
  }

  _applyDates(datePosted){
    let posted = this._formatFilterIds(datePosted).slice(0, -1) || 'all'

    this.setState({
      dateFilter: `date_posted=${posted}`,
    })

    this._scrollToTop()
  }

  _scrollToTop(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }

  _formatFilterIds(filterIds){
    let filter = '';

    if(!filterIds) return filter

    if(filterIds.indexOf('All') < 0){
      filterIds.map(filterId => {
        if (filterId != ''){
          filter = `${filter}${filterId},`
        }
      });
    }

    return filter;
  }

  _renderJobs(){

    let {
      selectedJob,
      searchTerm,
      location,
      contractsFilter,
      dateFilter,
      setUp,
      partner,
      pageCount,
      companySlug,
      page
    } = this.state

    let className = '';

    let search = `search=${searchTerm}${searchTerm !== '' ? '&es=true' : '&es=false'}&location=${location}`

    return (
      <>
        <Paginator
          endpoint={`${window.Api.Channels}/${companySlug || partner.slug}/feed?page_size=20&pagination_type=page&source=${partner.slug}&${search}`}
          pageCount={pageCount}
          onItemUpdated={items => {
            if(!selectedJob){
              this.setState({
                items,
                loading: false,
              }, () => this._setSelectedJob(items[0]))
            }else{
              this.setState({
                items,
                loading: false
              })
            }
          }}
          renderItem={(job) => {
            className = job === selectedJob ? 'active' : '';
            let jobLink = window.General.JobPage.replace("[company]", job.company.slug).replace("[job]", job.slug)
            if(job.jobkey){
              jobLink = job.redirect
            }
            return (
              <JobItem
                job={job}
                jobLink={jobLink}
                className={className}
                onClick={(job) => this._setSelectedJob(job)}
                page={page}
              />
            )
          }}
          renderInitialLoading={() => {
            return (
              <>
                <JobShimmerItem page={page} />
                <JobShimmerItem page={page} />
                <JobShimmerItem page={page} />
                <JobShimmerItem page={page} />
              </>
            )
          }}
        />
      </>
    )
  }

  _setSelectedJob(job){
    let{
      partner,
      selectedJob
    } = this.state

    if(!job) return

    let jobPage = window.General.JobPage.replace("[company]", job.company.slug).replace("[job]", job.slug);

    if(!selectedJob){
      this.setState({selectedJob: job})
      return;
    }

    if(isMobile){
      if(job.redirect){
        if(!job.jobkey) {
          job.redirect = Indeed.getIndeedTrackingLink(job.redirect, partner.slug)
          Backend.jobRedirect(job, partner, job.redirect)
        }
        window.open(job.redirect, '_blank');
      }else{
        window.open(jobPage, '_self');
      }
    }else{
      if(!job.jobkey){
        Backend.jobView(job, partner)
      }
      if(job.redirect){
        if(!job.jobkey) {
          job.redirect = Indeed.getIndeedTrackingLink(job.redirect, partner.slug)
          Backend.jobRedirect(job, partner, job.redirect)
        }
        window.open(job.redirect, '_blank');
      }
      this.setState({selectedJob: null})
      window.$('.search-job-detail-container').animate({
        scrollTop: 0
      }, 800);
      this.setState({selectedJob: job})
    }
  }

  _renderDescription(){
    let{ partner, selectedJob, page } = this.state

    if(this.state.items.length === 0 && !this.state.loading){
      return null
    }

    if(selectedJob == null){
      return <JobShimmerDescription/>
    }

    return (
      <JobDescription
        partner={partner}
        selectedJob={selectedJob}
        page={page}
      />
    )
  }

  render() {
    let {
      items,
      loading,
      partner,
      noResults,
      page
    } = this.state

    let leftColClasses = "col-lg-4"
    if(page === "company-job"){
      leftColClasses = "col-lg-5";
    }

    return (
      <>
        <div className="row columns justify-content-center" style={{ display: 'flex', alignItems: 'flex-start' }}>
          {
            !noResults &&
            <>
              <div 
                className={"search-jobs " + leftColClasses}
              >
                <div className={"section"}>
                  <div className={"job-cards"}>
                    {this._renderJobs()}
                  </div>
                </div>
              </div>
              <StickyBox 
                offsetTop={225} 
                className={"job-description col-lg-7"}
              >
                { this._renderDescription() }
              </StickyBox>
            </>
          }
          {
            (items.length === 0 && !loading) &&
            <div className="col-lg-6 search-jobs text-center">
              <h3>No jobs right now, check back soon!</h3>
            </div>
          }

        </div>
      </>
    )
  }
}
