import React from "react";

import NavigationBar from "./components/common/NavigationBar";
import Footer from "./components/common/Footer";

import Banner from "./components/search/Banner";
import General from "../utils/General";
import CompanyResults from "./components/search/CompanyResults";
import ScriptCache from "../utils/ScriptCache";
import MetaTags from "../utils/MetaTags";
import Backend from "../utils/Backend";

export default class SearchJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      filters: null,
      searchTerm: General.getUrlParameter("searchTerm"),
    }
  }

  componentDidMount() {
    let data = General.getTrafficData(this.props.partner)
    data["search_companies_page"] = true
    Backend.logTraffic(data)
  }

  render() {
    let {
      searchTerm,
      partner,
      filters
    } = this.state

    let shareTitle = `Search Companies - Find Your Company Today! | ${partner.name}`;
    let description = `Search companies on ${partner.name} that are looking for great talent like you. You'll be able 
                       to learn about their cultures and activities. Begin your search and start applying today!`;
    let url = window.location.href;
    let shareImage = partner.logo;

    return (
      <>
        { MetaTags.render(shareTitle, description, null, url, shareImage) }
        <NavigationBar
          opacity={'bar--absolute '}
          partner={partner}
          headerFixed='true'
        />
        <div className={'main-container full-page '}>
          <Banner
            searchTerm={searchTerm}
            filters={filters}
            page={"companies"}
          />
          <section className="search-tabs">
            <div className="container">
              <CompanyResults
                searchTerm={searchTerm}
                partner={partner}
              />
            </div>
          </section>
          <Footer
            partner={partner}
          />
        </div>
      </>
    )
  }
}