import React from "react";

import SquareLogo from "./SquareLogo";
import {Link} from "react-router-dom";
import FetchHelper from "../../../utils/FetchHelper";
import General from "../../../utils/General";
import Indeed from "../../../utils/Indeed";
import Backend from "../../../utils/Backend";

export default class JobItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      partner: props.partner
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _handleOnClick(){
    let {
      job,
      partner
    } = this.state

    let jobPage = window.General.JobPage.replace("[company]", job.company.slug).replace("[job]", job.slug);

    if(job.redirect){
      job.redirect = Indeed.getIndeedTrackingLink(job.redirect, partner)
      Backend.jobRedirect(job, partner, job.redirect).then(() => {
        window.open(job.redirect, '_blank');
        window.open(jobPage, '_self');
      })
    }else{
      window.open(jobPage, '_self');
    }
  }

  render() {
    let {
      job,
      partner
    } = this.state

    let impressionUrl = !!job.impression_url
    let jobPage = window.General.JobPage.replace("[company]", job.company.slug).replace("[job]", job.slug);

    return (
      <>
        <a
          href={jobPage}
          onClick={e => {
            e.preventDefault()
            this._handleOnClick()
          }}
          style={{cursor: 'pointer'}}
          className="row boxed boxed--border"
        >
          {impressionUrl && Indeed.setIndeedImpressionImage(job.impression_url)}
          <SquareLogo
            job={job}
          />
          <span className={"col-sm-12"}>
            <h4>{job.title}</h4>
            <ul className={"list-inline"}>
              <li><i className="icon icon--sm icon-Suitcase"></i>{job.company.name}</li>
              <li><i className="icon icon--sm icon-Map-Marker"></i>{job.location.city || job.location.state || job.location.country}</li>
            </ul>
          </span>
        </a>
      </>
    )
  }
}