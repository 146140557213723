import React from 'react';
import './App.css';

import Api from './constants/Api'
import General from './constants/General'

import GeneralUtil from './utils/General'
import Indeed from './utils/Indeed'

import './assets/css/stack-interface.css'
import './assets/css/socicon.css'
import './assets/css/iconsmind.css'
import './assets/css/bootstrap.css'
import './assets/css/theme-red.css'
import './assets/css/custom.css'
import './assets/css/font-awesome.min.css'
import './assets/css/flickity.css'
import './assets/scss/app.scss'

import { BrowserRouter, Switch } from 'react-router-dom';
import Route from './components/UnauthenticatedRoute';

import { Helmet } from 'react-helmet';

import SiteApp from './pages/App'
import Job from './pages/Job'
import CompanyJobs from './pages/CompanyJobs'
import Company from './pages/Company'
import Channel from './pages/Channel'
import SearchJobs from './pages/SearchJobs'
import SearchCompanies from './pages/SearchCompanies'
import SearchArticles from './pages/SearchArticles'
import Article from "./pages/Article";
import Backend from "./utils/Backend";
import PartnerBranding from "./pages/components/common/PartnerBranding";
import PreLoad from "./pages/components/common/PreLoad";

import TagManager from 'react-gtm-module'

export default class App extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      partner: null,
      source: GeneralUtil.getUrlParameter("source"),
    }
  }

  componentDidMount() {
    this._setup()
  }

  async _setup(){
    let domain = window.location.host

    if(domain.includes("localhost")){
      domain = "dev-partner.jobbio.com"
    }

    let pathname = window.location.pathname

    let companySlug = null
    let jobSlug = null
    let channelSlug = null
    let articleSlug = null

    let indexMode = false
    let jobMode = false
    let companyMode = false
    let companyJobsMode = false
    let channelMode = false
    let articleMode = false
    let jobsMode = false
    let companiesMode = false
    let articlesMode = false
    let mode = "index"

    if(pathname !== null){
      pathname = pathname.substring(1, window.location.pathname.length).split("/")

      jobMode = pathname.includes("job")
      companyJobsMode = (pathname.includes("company") && pathname.includes("jobs")) && (pathname.indexOf("company") < pathname.lastIndexOf("jobs"))
      companyMode = pathname.includes("company")
      channelMode = pathname.includes("channel")
      articleMode = pathname.includes("article")
      jobsMode = pathname.includes("search") && pathname.includes("jobs")
      companiesMode = pathname.includes("search") && pathname.includes("companies")
      articlesMode = pathname.includes("search") && pathname.includes("articles")

      if(!jobMode && !companyMode && !channelMode && !articleMode && !jobsMode && !companiesMode && !articlesMode){
        indexMode = true
      }else if(jobMode){
        pathname = pathname[pathname.indexOf("job")+1].split("-at-")
        companySlug = pathname[1]
        jobSlug = pathname[0]
        mode = "job"
      }else if(companyJobsMode){
        companySlug = pathname[pathname.indexOf("company")+1]
        mode = "company_jobs"
      }else if(companyMode){
        companySlug = pathname[pathname.indexOf("company")+1]
        mode = "company"
      }else if(channelMode){
        channelSlug = pathname[pathname.indexOf("channel")+1]
        mode = "sub-channel"
      }else if(articleMode){
        articleSlug = pathname[pathname.indexOf("article")+1]
        mode = "article"
      }else if(jobsMode){
        mode = "job_search"
      }else if(companiesMode){
        mode = "company_search"
      }else if(articlesMode){
        mode = "article_search"
      }
    }

    let pageData = null
    try {
      pageData = await Backend.getPageData(domain, mode, companySlug, jobSlug, channelSlug, articleSlug)

      let partner = pageData.partner_data

      this._setTracking(partner)

      if(!window.location.host.includes("localhost")) {
        window.General.HomePage = partner.routes.home_url
        window.General.ChannelPage = partner.routes.channel_url
        window.General.CompanyPage = partner.routes.company_url
        window.General.CompanyJobsPage = partner.routes.company_jobs_url
        window.General.JobPage = partner.routes.job_url
        window.General.ArticlePage = partner.routes.article_url
        window.General.CompaniesPage = partner.routes.companies_url
        window.General.JobsPage = partner.routes.jobs_url
        window.General.ArticlesPage = partner.routes.articles_url
      }
      window.Api.GoogleMapsKey = partner.settings.gmaps_key
    }catch (e){
      console.log(e)
    }


    this.setState({
      indexMode,
      jobMode,
      companyJobsMode,
      companyMode,
      channelMode,
      articleMode,
      jobsMode,
      companiesMode,
      articlesMode,
      companySlug,
      jobSlug,
      channelSlug,
      articleSlug,
      pageData
    })
  }

  _setTracking(partner){

    let gtmId = partner.settings.gtm_id

    if(!gtmId){
      return
    }

    const tagManagerArgs = {
      gtmId: gtmId
    }

    TagManager.initialize(tagManagerArgs)
  }

  _renderContent(){
    let {
      indexMode,
      jobMode,
      companyJobsMode,
      companyMode,
      channelMode,
      articleMode,
      jobsMode,
      companiesMode,
      articlesMode,
      companySlug,
      jobSlug,
      channelSlug,
      articleSlug,
      pageData,
      source
    } = this.state

    let partner = pageData.partner_data

    if(indexMode){
      return <SiteApp partner={partner} companies={pageData.company_data.results} articleData={pageData.article_data.results} subChannelData={pageData.partner_data.channels.results} />
    }

    if(jobMode){
      return <Job partner={partner} job={pageData.job_data} jobs={pageData.similar_jobs_data.results} companySlug={companySlug} jobSlug={jobSlug} source={`${source}`} />
    }

    if(companyJobsMode){
      return <CompanyJobs partner={partner} channel={pageData.company_data} companySlug={companySlug} />
    }

    if(companyMode){
      return <Company partner={partner} channel={pageData.company_data} jobs={pageData.feed_data} blocks={pageData.content_data} companySlug={companySlug} />
    }

    if(channelMode){
      return <Channel partner={partner} channel={pageData.channel_data} jobs={pageData.feed_data} channelSlug={channelSlug} />
    }

    if(articleMode){
      return <Article partner={partner} article={pageData.article_data} articles={pageData.related_articles_data.results} articleSlug={articleSlug} />
    }

    if(companiesMode){
      return <SearchCompanies partner={partner} />
    }

    if(articlesMode){
      return <SearchArticles partner={partner} />
    }

    if(jobsMode){
      return <SearchJobs partner={partner} />
    }
  }

  render() {
    let {
      pageData
    } = this.state

    if(!pageData) return <PreLoad/>

    let partner = pageData.partner_data

    let favicon = partner.settings?.favicon || partner.channel_data?.settings.favicon || 'https://partner.jobbio.com/favicon.ico'

    let index = true
    if(window.Api.Base !== "https://partner-api.jobbio.com"){
      index = false
    }

    return (
      <>
        <Helmet>
          <link rel={"icon"} href={favicon} />
          {
            !index && <meta name="robots" content="noindex"/>
          }
        </Helmet>
        <PartnerBranding partner={partner} />
        {this._renderContent()}
      </>
    )
  }
}
