import React from "react";
import {Link} from "react-router-dom";
import CookiePolicyPopUp from "./CookiePolicyPopUp";
import $ from 'jquery';

export default class NavigationBar extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      opacity: props.opacity,
      partner: props.partner,
      headerFixed:props.headerFixed

    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount() {

    // starts : header fixed
    $(window).scroll(function() {    
      var scroll = $(window).scrollTop();
      if (scroll > 366) {
        $('.header-fixed nav#menu1').addClass('pos-fixed');
        $('.header-fixed').addClass('h-pos-fixed');
      } else {
        $('.header-fixed nav#menu1').removeClass('pos-fixed');
        $('.header-fixed').removeClass('h-pos-fixed');
      }
    });
    // end : header fixed

  }

  _renderMenuLinks(){
    let {
      partner
    } = this.state

    return partner.info.map(item => {
      if(item.type === "content"){
        return (
          <li>
            <a
              data-scroll
              href={"#info-tabs"}
              onClick={() => {
                window.mr.tabs.activateTab(`#list${item.id}`)
              }}
            >
              {item.title}
            </a>
          </li>
        )
      }
    })
  }

  render() {
    let {
      partner,
      headerFixed
    } = this.state

    let homeLink = window.General.HomePage
    let jobsLink = window.General.JobsPage
    let companiesLink = window.General.CompaniesPage
    let articlesLink = window.General.ArticlesPage

    let parentClass = "nav-container"
    if (headerFixed === "true"){
      parentClass += " header-fixed"
    }

    return (
      <>
        <CookiePolicyPopUp
          partner={partner}
        />
        <div id="nav-container" className={parentClass}>
          <div>

            <div className="bar bar--sm visible-xs">
              <div className="container">
                <div className="row">
                  <div className="col-3 col-md-2">
                    <a href={partner.settings.menu_logo_url}>
                      <img className="logo logo-dark" alt="logo" src={partner.settings.menu_secondary_logo || partner.settings.menu_logo} />
                      <img className="logo logo-light" alt="logo" src={partner.settings.menu_logo} />
                    </a>
                  </div>
                  <div className="col-9 col-md-10 text-right">
                    <a href="#" className="hamburger-toggle" data-toggle-class="#menu1;hidden-xs hidden-sm">
                      <i className="icon icon--sm stack-interface stack-menu"></i> </a>
                  </div>
                </div>
              </div>
            </div>
            
            <nav id="menu1" className={`bar bar-1 hidden-xs ${this.state.opacity || ''}` }>
              <div className="container">
                <div className="row">
                  <div className="col-lg-1 col-md-2 hidden-xs">
                    <div className="bar__module">
                      <a href={partner.settings.menu_logo_url}>
                        <img className="logo logo-dark" alt="logo" src={partner.settings.menu_secondary_logo || partner.settings.menu_logo} />
                        <img className="logo logo-light" alt="logo" src={partner.settings.menu_logo} />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-11 col-md-10 text-right text-left-xs text-left-sm">
                    <div className="bar__module">
                      <ul className="menu-horizontal text-left">
                        <li>
                          <a href={homeLink}>
                            Home
                          </a>
                        </li>
                        <li>
                          <a href={jobsLink}>
                            Jobs
                          </a>
                        </li>
                        <li>
                          <a href={companiesLink}>
                            Companies
                          </a>
                        </li>
                        <li>
                          <a href={articlesLink}>
                            Articles
                          </a>
                        </li>
                        {
                          this.props.showMenu && this._renderMenuLinks()
                        }
                      </ul>
                    </div>
                    <div className="bar__module">
                      <a href={partner.settings.menu_job_url} target="_blank" className="btn btn--sm type--uppercase">
                        <span className="btn__text">{partner.settings.menu_job_cta}</span>
                      </a>
                      <a href={`https://jobbio.com/talent/register?channel=${partner.slug}&source=${partner.slug}`} target="_blank" className="btn btn--sm btn--primary type--uppercase">
                        <span className="btn__text">{partner.settings.menu_alert_cta}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          
          </div>
        </div>
      </>
    )
  }
}

NavigationBar.defaultProps = {
  showMenu: false
}