import React from "react";
import $ from 'jquery';
import StickyBox from "react-sticky-box";

import NavigationBar from "./components/common/NavigationBar";
import OtherResults from "./components/common/OtherResults";
import CTASection from "./components/common/CTASection";
import Footer from "./components/common/Footer";
import SearchBar from "./components/common/SearchBar";
import NotFound from "./components/common/NotFound";

import Backend from "../utils/Backend";
import General from "../utils/General";
import Indeed from "../utils/Indeed";
import Banner from "./components/common/Banner";
import JobBanner from "./components/job/Banner"
import Description from "./components/job/Description";
import CompanyCard from "./components/job/CompanyCard";
import PreLoad from "./components/common/PreLoad";
import PartnerBranding from "./components/common/PartnerBranding";
import ScriptCache from "../utils/ScriptCache";
import MetaTags from "../utils/MetaTags";
import JobPostingSchema from "./components/job/JobPostingSchema";
import JobResults from "./components/search/JobResults";
import GeneralUtil from "../utils/General";

export default class Job extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      job: props.job,
      jobs: props.jobs,
      source: props.source,
      jobNotFound: props.job.detail === "An Instance was not found with the given ID",
      redirectUrl: GeneralUtil.getUrlParameter("redirect-url"),
    }
  }

  componentDidMount() {
    this._loadJs()
    this._logTraffic()
    // starts : search bar fixed
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();
      if (scroll > 366) {
        $('.bar-search').addClass('pos-fixed');
      } else {
        $('.bar-search').removeClass('pos-fixed');
      }
    });
    // end : search bar fixed
    if(this.state.redirectUrl) {
      let url = Indeed.getIndeedTrackingLink(this.state.job.redirect, this.state.source);
      Backend.jobRedirect({id: this.state.job.id}, {slug: `${this.state.source}`}, url).then(() => {
        window.open(url, '_self');
      })
    }

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this._logTraffic()
  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }

  _logTraffic(){
    let data = General.getTrafficData(this.state.partner)
    data["job"] = this.state.job.id
    Backend.logTraffic(data)
  }

  render() {
    let{
      job,
      partner,
      jobs,
      source,
      jobNotFound
    } = this.state

    if(!job) return <PreLoad/>
    if(jobNotFound) return (
      <NotFound type={"jobs"} partner={partner}>
        <section className="maincolumns job sticky-sidebars">
          <div className="container">
            <div className="">
              <h4 className="color--error text-center">
                The job you're looking for is no longer active but you can search for other roles or find similar jobs below.
              </h4>
              <section className="search-tabs" style={{paddingTop: '3em'}}>
                <div className="container full-page">
                  <JobResults
                    searchTerm={""}
                    location={""}
                    filters={""}
                    partner={partner}
                  />
                </div>
              </section>
            </div>
          </div>
        </section>
      </NotFound>
    )

    let applyUrl = `https://jobbio.com/talent/register?app_source=${partner.slug}&job=${job.id}&source=${partner.slug}`;
    let viewMore = window.General.CompanyJobsPage.replace("[company]", job.company.slug)

    if (job.redirect) {
      applyUrl = Indeed.getIndeedTrackingLink(job.redirect, partner.slug);
    }

    let shareTitle = `${job.title} wanted at ${job.company.name} in ${job.location.country} | ${partner.name}`;
    let description = `${job.title} wanted at ${job.company.name} in ${job.location.country}. Don’t miss this opportunity and show your interest in the position now!`
    let url = window.location.href;
    let shareImage = partner.logo;

    let canonicalLink = `${window.General.JobbioSite}/companies/${job.company.slug}/jobs/${job.slug}`

    let impressionUrl = !!job.impression_url

    partner.slug = source || partner.slug

    return (
      <div className="job-v2">

        { MetaTags.render(shareTitle, description, null, url, shareImage, canonicalLink) }
        {/*{impressionUrl && Indeed.setIndeedImpressionImage(job.impression_url)}*/}

        <NavigationBar
          partner={partner}
          opacity={' bar--absolute '}
          headerFixed='true'
        />
        <div className="bar-search">
          <div className="container">
            <div className="row">
              <div className="col">
                <SearchBar
                  page={"jobs"}
                />
              </div>
            </div>
          </div>
        </div>

        <section className="maincolumns job sticky-sidebars">
          <div className="container">
            <div className="row">
              <CompanyCard
                job={job}
                partner={partner}
                variation="v2"
              />
              <Description
                job={job}
                partner={partner}
                variation="v2"
              />
              <OtherResults
                partner={partner}
                title={'Similar Jobs'}
                objects={jobs}
                viewMore={viewMore}
                className={"pb-4"}
                variation="v2"
              />
            </div>
          </div>
        </section>
        <JobPostingSchema job={job} />

        <Footer
          partner={partner}
        />

      </div>
    )
  }
}
