import React from "react";
import {Link} from "react-router-dom";
import SquareLogo from "../common/SquareLogo";
import Backend from "../../../utils/Backend";
import Indeed from "../../../utils/Indeed";

export default class OtherCompanies extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      type: props.type,
      title: props.title,
      companies: props.companies,
      partner: props.partner,
      viewMore: props.viewMore,
      variation: props.variation
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderObjects(){
    let {
      companies,
    } = this.state

    return companies.map(company => {
      let ctaText = company.live_jobs > 0 ? `${company.live_jobs} Jobs` : 'View Company'
      let companyLink = window.General.CompanyPage.replace("[company]", company.slug)
      return (
        <a className="row" href={companyLink}>
          <SquareLogo
            company={company}
          />
          <span className="col-sm-12">
              <span>{company.name}</span>
              <small>{ctaText}</small>
            </span>
        </a>
      )
    })
  }

  render() {
    let {
      type,
      title,
      companies,
      viewMore,
      variation
    } = this.state

    if(!companies) return null

    let parentClassName = "job-other col-lg-3 leftcol "
    if(this.props.className!=""){
      parentClassName += this.props.className;
    }
    if(variation!="v2"){
      parentClassName += " col-lg-pull-6"
    }

    return (
      <>
        <div className={parentClassName}>
          <div className="sidebar__inner">
            <div className="section">
              <h4 id="companies-title">{title}</h4>
              <div className="feature feature-1 boxed boxed--border popularcompanies">
                {
                  companies.length > 0 ?
                  this._renderObjects() :
                  <h4>Companies will be displayed here</h4>
                }
              </div>
              <div className="view-more" id="view-more-companies">
                <a
                  href={viewMore}
                >
                  View More
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}