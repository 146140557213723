import React from "react";
import {Link} from "react-router-dom";
import SquareLogo from "./SquareLogo";
import Backend from "../../../utils/Backend";
import Indeed from "../../../utils/Indeed";

export default class OtherResults extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      type: props.type,
      title: props.title,
      objects: props.objects,
      partner: props.partner,
      viewMore: props.viewMore,
      variation: props.variation
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _handleOnClick(object){
    let {
      partner
    } = this.state

    let jobPage = window.General.JobPage.replace("[company]", object.company.slug).replace("[job]", object.slug)

    if(object.redirect){
      object.redirect = Indeed.getIndeedTrackingLink(object.redirect, partner)
      Backend.jobRedirect(object, partner, object.redirect).then(() => {
        window.open(object.redirect, '_blank');
        window.open(jobPage, '_self');
      })
    }else{
      window.open(jobPage, '_self');
    }
  }

  _renderObjects(){
    let {
      objects,
      type
    } = this.state

    return objects.map(object => {
      if(type === "company"){
        let ctaText = object.live_jobs > 0 ? `${object.live_jobs} Jobs` : 'View Company'
        let companyLink = window.General.CompanyPage.replace("[company]", object.slug)
        return (
          <a className="row" href={companyLink}>
            <SquareLogo
              company={object}
            />
            <span className="col-sm-12">
              <span>{object.name}</span>
              <small>{ctaText}</small>
            </span>
          </a>
        )
      }

      let jobPage = window.General.JobPage.replace("[company]", object.company.slug).replace("[job]", object.slug)

      return (
        <a
          className="row"
          href={jobPage}
          style={{cursor: 'pointer'}}
          onClick={e => {
            e.preventDefault()
            this._handleOnClick(object)
          }}
        >
          <SquareLogo
            job={object}
          />
          <span className="col-sm-12">
            <span>{object.title}</span>
            <small>{object.company.name}</small>
          </span>
        </a>
      )
    })
  }

  render() {
    let {
      type,
      title,
      objects,
      viewMore,
      variation
    } = this.state

    if(!objects) return null

    let parentClassName = "job-other col-lg-3 leftcol "
    if(this.props.className!=""){
      parentClassName += this.props.className;
    }
    if(variation!="v2"){
      parentClassName += " col-lg-pull-6"
    }

    return (
      <>
        <div className={parentClassName}>
          <div className="sidebar__inner">
            <div className="section">
              <h4 id="companies-title">{title}</h4>
              <div className="feature feature-1 boxed boxed--border popularcompanies">
                {
                  objects.length > 0 ?
                  this._renderObjects() :
                  (type === "company" && <h4>Companies will be displayed here</h4>) ||
                  (type === "jobs" && <h4>This company currently has no other jobs.</h4>)
                }
              </div>
              <div className="view-more" id="view-more-companies">
                <a
                  href={viewMore}
                >
                  View More
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}